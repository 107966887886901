<template>
    <Layout>
        <v-container class="my-md-12 my-5">
            <v-row justify="center" class="mt-10">
                <v-row class="mx-1">
                    <v-col cols="12" class="text-left pt-10">
                        <h1 v-once v-html="$t_('recuperacao')"></h1>
                    </v-col>
                </v-row>
                <v-col cols="12" md="6">
                    <v-row align="center">

                        <v-col cols="12">
                            <label required>{{ $t_('senha') }}</label>
                            <v-tooltip bottom max-width="300">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="primary" dark v-bind="attrs" v-on="on"
                                            style="cursor: help; position: relative; margin-left: 5px; width: 17px; height: 17px">
                                            mdi-information mdi-color-gray
                                        </v-icon>
                                    </template>
                                    <span v-once>{{$t_('tip_senha')}}</span>
                                </v-tooltip>
                            <v-text-field class="mt-3" v-model="dados.password" hide-details dense outlined
                                :type="show1 ? 'text' : 'password'"
                                :append-icon="show1 ? 'mdi-eye mdi-color-gray' : 'mdi-eye-off mdi-color-gray'"
                                @click:append="show1 = !show1" placeholder="*******" tabindex="1"></v-text-field>
                            <v-progress-linear v-model="chekingPassword"
                                :color="chekingPassword < 60 ? 'red' : chekingPassword < 90 ? 'orange' : 'green'"
                                height="5"></v-progress-linear>
                        </v-col>

                        <v-col cols="12">
                            <label required>{{ $t_('confirmar_senha') }}</label>
                            <v-text-field class="mt-3" v-model="senha2" hide-details dense outlined :type="'password'"
                                placeholder="*******" tabindex="2"></v-text-field>
                            <v-progress-linear :value="0" color="white" height="23"></v-progress-linear>
                        </v-col>

                        <v-col cols="12" md="5" class="text-left">
                            <v-btn x-large class="mr-md-3" :loading="$usuario.cadastro.loading" style="color: white"
                                :color="$config.dados.corprimaria" @click="recuperarSenha()" block elevation="0"
                                tabindex="3"><strong>{{$t_('recuperar_btn')}}</strong></v-btn>
                        </v-col>

                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </Layout>
</template>
<script>
    export default {
        name: 'RercuperarSenha', 
        components: {
            Layout: () => import('@/layout/Default.vue')
        },
        data() {
            return {
                show1: false,
                dados: {
                    username: '',
                    password: ''
                },
                senha2: ''
            }
        },
        computed: {
            chekingPassword() {
                return this.$usuario.validaSenha(this.dados.password)
            }
        },
        methods: {
            recuperarSenha() {
                if(this.$usuario.validaSenha(this.dados.password) < 60)
                    { this.$config.alert(this.$t_('preencha_senha_corret')); return }
                if(this.dados.password != this.senha2)
                    { this.$config.alert(this.$t_('senhas_incompativeis')); return }

                this.$usuario.recoverySenha(this.dados);

            },
            getParams() {
                if(this.$route.query.token){
                    this.dados.username = this.$route.query.token;
                } else {
                    this.$router.push({ name: 'Home' })
                }
            }   
        },
        mounted() {
            this.getParams()    
        }  
    }
</script>